import { roles } from './../routes';
import { AuthorizeStep } from 'authorizeStep';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';

@autoinject
export class StartComponent {
  constructor(private router: Router) {
    if (AuthorizeStep.auth.roles) {
      this.navigateToStartComponent();
    } else {
      setTimeout(() => {
        this.retryDefaultNavigation();
      }, 100);
    }
  }

  private retryDefaultNavigation() {
    if (AuthorizeStep.auth.roles) {
      this.navigateToStartComponent();
    } else {
      setTimeout(() => {
        this.retryDefaultNavigation();
      }, 100);
    }
  }

  private navigateToStartComponent() {
    if (AuthorizeStep.auth.roles.find((x) => x == roles.sales || x == roles.administrator)) {
      this.router.navigate('dashboard');
    } else if (AuthorizeStep.auth.roles.find((x) => x == roles.serviceuser)) {
      this.router.navigate('services');
    } else if (AuthorizeStep.auth.roles.find((x) => x == roles.production || x == roles.productioncoordinator)) {
      this.router.navigate('orders');
    } else if (AuthorizeStep.auth.roles.find((x) => x == roles.leader)) {
      this.router.navigate('management');
    }
  }
}
